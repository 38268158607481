exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-english-book-ladder-js": () => import("./../../../src/pages/english-book-ladder.js" /* webpackChunkName: "component---src-pages-english-book-ladder-js" */),
  "component---src-pages-first-string-digital-js": () => import("./../../../src/pages/first-string-digital.js" /* webpackChunkName: "component---src-pages-first-string-digital-js" */),
  "component---src-pages-icbc-js": () => import("./../../../src/pages/icbc.js" /* webpackChunkName: "component---src-pages-icbc-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-numbersusa-js": () => import("./../../../src/pages/numbersusa.js" /* webpackChunkName: "component---src-pages-numbersusa-js" */)
}

